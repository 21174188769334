import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../App.css";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="message"
    >

      <Modal.Body>
        <p className="mt-2">
          Thank you for your application to register your interest in our
          company.{" "}
        </p>
        <p>
          We have received a large number of applications, however due to
          delivering exceptional service to our current clients, delivering a
          continuation of supply on all of our product lines and maintaining a
          competitive pricing structure we are unable to bring on any new
          clients at this stage.
          </p> 
          <p>
          We will keep your
          information on hand and contact you at a time that we feel best suited
          to deliver you and your business the same service as detailed above.
        </p>

        <Button className="me-3" style={{float:'right'}} onClick={props.onHide}>Close</Button>
      </Modal.Body>

    </Modal>
  );
}
function Main() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        className="message-btn"
        variant="primary"
        size="lg"
        onClick={() => setModalShow(true)}
        style={{ marginLeft: "40%", marginTop: "10%" }}
      >
        A Message From CTL Australia
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Main;
