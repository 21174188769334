import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SplashPage from "./pages/SplashPage";
import Main from "./pages/components/Main";




function App() {
  return (
    <>

        <Router>


          <Routes>
            {/* publicly available routes: */}

            <Route path="/" element={<SplashPage />} />
            <Route path="/unfortunately" element={<Main />} />

            <Route path="*" element="Page not exists 404" />
          </Routes>

        </Router>

    </>
  );
}

export default App;
